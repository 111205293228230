/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
import $ from 'jquery'
import {} from 'jquery-ujs'
import {} from 'jquery-match-height'
import hoverIntent from 'hoverintent'
import PerfectScrollbar from 'perfect-scrollbar'
import Swiper from 'swiper'
import LazyLoad from "vanilla-lazyload"
import 'bootstrap'
import './vendor/jquery.hideseek'

import { bindGYEvents } from './gy-events'
import { bindOrderEvents } from './order'

window.$ = window.jQuery = $

const GY = {
  settings: {
    homepageSlider: $('.homepage-slider').find('.swiper-container'),
    homepageArticleSlider: $('.homepage-article-slider').find('.swiper-container'),
    homepageTotalLookSlider: $('.homepage-slider-total-look').find('.swiper-container')
  },

  init: function () {
    bindGYEvents()
    bindOrderEvents()

    var s = this.settings // Settings Caching
    var debounce = this.debounce()
    //*** BUNCH OF METHODS TO RUN ON INITIALIZATION ***//

    this.generalChecks() // Some General checks
    this.initMainMenu() // Initializes main menu
    this.initHomepageSlider() // Initializes Swiper for the main hero slider in homepage
    this.initHomepageArticleSlider() // Initializes Swiper for the article slider in homepage
    this.inithomepageTotalLookSlider() // Initializes Swiper for total look slider in homepage
    this.productInit() // Initializes Product
    this.panelsInit() // Initializes Product
    this.windowResize() // Binds events on window resize
    this.responsiveMenu() // Responsive menu functionality
    this.catalogFilters() // Catalog filters functionality
    this.productPage() // Product page functionality
    this.articlePage() // Article Page functionality
    this.faqToggler() // Simple FAQ toggler
    this.initStaticPagesMenu()
    this.stopPropagationSearch()
    this.openSearch()
    this.closeSearch()
    this.searchInit2()
    this.sizeGuide() // Size Guide Product Page
    this.hoverElement() //Hover Element
    this.tabbableSectionsHomepageMobile()
    this.animate()
    this.mobileCatalogFilter()
    this.addClassSlideInUp()
    this.unfreezeBackground()
  },

  debounce: function (func, wait, immediate) {
    var timeout
    return function () {
      var context = this,
        args = arguments
      var later = function () {
        timeout = null
        if (!immediate) func.apply(context, args)
      }
      var callNow = immediate && !timeout
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
      if (callNow) func.apply(context, args)
    }
  },

  windowResize: function () {
    $(window).resize(function() {
      GY.mobileCatalogFilter()
    })
  },

  stopPropagationSearch: function () {
    $('html').on('click', function (e) {
      let containerclicked = $('.search-form')
      if (!containerclicked.is(e.target) && containerclicked.has(e.target).length === 0) {
        $('.search-form').removeClass('has-results')
      }
    })
  },

  freezeBackground() {
    $('html, body').css({
      overflow: 'hidden',
      position: 'relative',
      height: '100%'
    })
  },

  unfreezeBackground() {
    window.unfreezeBackground = function () {
      $('html, body').css({
        overflow: 'auto',
        height: 'auto'
      })
    }
    window.unfreezeBackground()
  },

  generalChecks: function () {
    window.lazyLoadInstance = new LazyLoad({
      elements_selector: "[lazyload]"
    })

    // Experts question stop propagation in FAQ items.
    if ($(".answer-item").length) {

      $(".answer-item").on("click", function (e) {
        e.stopPropagation()
      })
    }

    window.eqHeight = function () {
      $(".eq").matchHeight({
        byRow: false
      })
    }

    window.eqHeight()

    // Scrollable Init
    this.scrollableInit()

    if ($('.homepage-brands__search').length) {
      const input = $('.homepage-brands__search input')
      input.hideseek()

      const el = $('.homepage-brands__search .scrollable-vertical').get(0)

      const scrollbar = new PerfectScrollbar(el, {
        wheelPropagation: true,
        wipeEasing: true
      })

      input.on('_after', function () {
        scrollbar.update()
      })
    }

    // Sticky Header
    var  header = $(".header");
    var  stickyClass = "sticky-header";
    var  heightHeader = header.height();

    $(window).scroll(function() {
      if( $(this).scrollTop() > heightHeader ) {
        header.addClass(stickyClass);
      } else {
        header.removeClass(stickyClass);
      }
    });
  },

  scrollableInit: function() {
    window.scrollableInit = function() {
      $(".scrollable, .main-menu--mobile, .catalog-filters--mobile").each(function () {
        const el = $(this).get(0)

        new PerfectScrollbar(el, {
          wheelSpeed: 2,
          wheelPropagation: true,
          minScrollbarLength: 60,
          swipeEasing: true
        })
      })
    }

    window.scrollableInit()
  },

  productInit: function () {
    window.productInit = function () {
      $(document).on('click', '.trigger-quickbuy', function (e) {
        e.preventDefault()
        $(this).parents('.product').addClass('active')
      })

      $(document).on('click', '.quick-buy__close', function (e) {
        e.preventDefault()
        $(this).closest('.product').removeClass('active')
      })
    }

     window.productInit()
  },

  panelsInit: function () {
    $('a[data-panel="minicart"]').on('click', function (e) {
      e.preventDefault()

      $('.minicart-panel').addClass('minicart-panel--active')
      $('body').css('overflow', 'hidden')
    })

    $('a[data-panel="search"]').on('click', function (e) {
      e.preventDefault()
      $('.search-panel').addClass('active-panel')
      $('body').css('overflow', 'hidden')
    })

    $('.minicart-panel__close').on('click', function (e) {
      e.preventDefault()
      $(this).parent().removeClass('minicart-panel--active')
      $('body').css('overflow', 'auto')
    })
  },

  articlePage: function () {
    if ($('.article-page').length) {
      $('.share-product').on('click', function (e) {
        e.preventDefault()
        $('.share-panel').addClass('active')
      })

      $('.close-share').on('click', function (e) {
        e.preventDefault()
        $('.share-panel').removeClass('active')
      })
    }
  },

  productPage: function () {
    if ($('.product-page').length) {

      $('.share-product').on('click', function (e) {
        e.preventDefault()
        $('.share-panel').addClass('active')
      })

      $('.close-share').on('click', function (e) {
        e.preventDefault()
        $('.share-panel').removeClass('active')
      })
    }
  },

  initHomepageSlider: function () {
    if (this.settings.homepageSlider.length) {
      const swiper = new Swiper(this.settings.homepageSlider.get(0), {
        slidesPerView: 1,
        preloadImages: false,
        lazy: true,
        speed: 1500,
        autoplay: {
          delay: 5500
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet: function (index, className) {
            let currentIndex = index + 1;
            let currentNumeber = currentIndex.toString().padStart(2, '0');
            return '<span class="' + className + '">' + (currentNumeber) + '</span>'
          },
        }
      })
    }
  },

  initHomepageArticleSlider: function () {
    if (this.settings.homepageArticleSlider.length) {
      new Swiper(this.settings.homepageArticleSlider.get(0), {
        slidesPerView: 3,
        speed: 1000,
        spaceBetween: 30,
        navigation: {
          nextEl: '.for-swiper-pagination .swiper-button-next',
          prevEl: '.for-swiper-pagination .swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination-article',
          type: 'fraction',
          formatFractionCurrent: function(number) {
            if(String.prototype.padStart) {
              return number.toString().padStart(2, '0')
            }
          },
          formatFractionTotal: function(number) {
            if(String.prototype.padStart) {
              return number.toString().padStart(2, '0')
            }
          }
        },
        breakpoints: {
          768: {
            slidesPerView: 2
          },
          // when window width is <= 320px
          576: {
            slidesPerView: 1
          },
        }
      });
    }
  },

  inithomepageTotalLookSlider: function () {
    if (this.settings.homepageTotalLookSlider.length) {
      new Swiper(this.settings.homepageTotalLookSlider.get(0), {
        effect: 'fade',
        speed: 1500,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination-total-look',
          type: 'fraction',
          formatFractionCurrent: function(number) {
            if(String.prototype.padStart) {
              return number.toString().padStart(2, '0')
            }
          },
          formatFractionTotal: function(number) {
            if(String.prototype.padStart) {
              return number.toString().padStart(2, '0')
            }
          }
        }
      })
    }
  },


  initStaticPagesMenu: function () {
    if ($(".sidebar-nav").length) {
      var urlpath = window.location.pathname

      $(".sidebar-nav li").each(function () {
        if (urlpath.indexOf($(this).data("menulink")) != -1) {
          $(this).addClass("active")
        }
      })
    }
  },

  // Initialize Menu functionality
  initMainMenu: () => {
    $(".main-menu--desktop .menu-item-l1").each(function() {
      let el = $(this)

      hoverIntent(el.get(0), function() {
         el.addClass('menu-item-l1--open')
       }, function() {
         el.removeClass('menu-item-l1--open')
       }).options({
         interval: 100
        }
      )
    })

    $(".main-menu--desktop .menu-item-l2").hover(
      function () {
        $(this).find('.menu-l3').addClass('menu-l3--open')
      },
      function () {
        $(this).find('.menu-l3').removeClass('menu-l3--open')
      }
    )
  },

  catalogFilters: function () {
    let $this = this

    window.catalogFilters = function () {
      $('.mobile-filter').not('.selected-filters').bind('clickoutside', function (event) {
        $(this).find('ul').removeClass('active')
      })

      $('.mobile-filter').not('.selected-filters').on('click', function (e) {
        var selected = $(this)

        if (selected.hasClass('closed')) {
          selected.removeClass('closed')
        } else {
          selected.addClass('closed')
        }
      })

      // Custom dropdown for catalog sorting/View filters.
      $('.custom-dropdown').on('click', function (e) {
        e.preventDefault()
        $(this).find('ul').toggleClass('active')
      })

      $('.custom-dropdown > span').each(function (i) {
        var _this = $(this).parent().attr('data-drop')

        $('.sort-drop').bind('clickoutside', function (event) {
          $(this).find('ul').removeClass('active')
        })

        $('.show-drop').bind('clickoutside', function (event) {
          $(this).find('ul').removeClass('active')
        })

      })

      $('.close-filters').on('click', function (e) {
        e.preventDefault()
        $('.catalog-filters').removeClass('catalog-filters--mobile d-block')
        $this.unfreezeBackground()
      })


      $('.filters-mobile > span').on('click', function (e) {
        $('.catalog-filters').addClass('catalog-filters--mobile d-block')
        $this.freezeBackground()
        $this.scrollableInit()
      })
    }

    window.catalogFilters()

  },

  faqToggler: function () {
    $('.faq-item').on('click', function () {
      $(this).toggleClass('active')
    })
  },

  // Responsive Menu
  responsiveMenu: function () {
    let $this = this

    $(document).on('click touch', '.desktop-menu-trigger', function (e) {
      e.preventDefault()
      $('.main-menu').removeClass('d-none').addClass('d-block')
      $this.freezeBackground()
    })

    $(document).on('click touch', '.mobile-menu-trigger', function (e) {
      e.preventDefault()
      $('.main-menu').removeClass('main-menu--desktop').addClass('main-menu--mobile d-block')
      $this.freezeBackground()
    })

    $(document).on('click touch', '.main-menu .close-mobile', function (e) {
      e.preventDefault()

      $('.main-menu').removeClass('main-menu--mobile d-block').addClass('main-menu--desktop')
      $this.unfreezeBackground()
      $this.scrollableInit()
    })

    $(document).on('click touch', '.menu-item-has-children', function (e) {
      $(this).toggleClass('active')
      $(this).find('> ul, >> ul').toggleClass('active')
    })

    $(document).on('click touch', '.menu-item-has-children', function (e) {
      e.stopPropagation()
    })
  },

  openSearch: function() {
    var _openSearch = $('.user-actions--trigger-search')
    var _searchPanel = $('.search-panel')

    _openSearch.on('click', function() {
      _searchPanel.addClass('open')
      $('body').css('overflow', 'hidden')
      setTimeout(function() {
        _searchPanel.find('.IZ-select__input input').focus()
      }, 100)
    })
  },

  closeSearch: function() {
    var _closeSearch = $('.search-panel .icon-cancel')
    var _searchPanel = $('.search-panel')

    _closeSearch.on('click', function() {
      _searchPanel.removeClass('open')
      $('body').css('overflow', 'auto')
    });
  },

  searchInit2: function() {
    $('.header-middle .icon-magnifying-glass').on('click', function() {
      $('.search-modal').addClass('open')
    })
    $('.search-modal .icon-close').on('click', function() {
      $('.search-modal').removeClass('open')
    })
  },

  sizeGuide: function() {
    $('body').on('click','.trigger-sizeguide', function(e) {
      e.preventDefault()
      const totalPage = $(this).closest('.totallook-item')

      if( totalPage.length > 0 ) {
        totalPage.find('.modal-sizeguide').addClass('active')
      } else {
        $('.modal-sizeguide').addClass('active')
      }
    })
    $('.modal-sizeguide .icon-close').on('click', function(){
      $(this).parent().parents('.modal-sizeguide').removeClass('active')
    })
  },

  hoverElement: function() {
    $('.user-account').hover(function() {
      $(this).closest('.header-top').toggleClass('header-top--black-shadow')
    });
  },

  tabbableSectionsHomepageMobile: function() {
    var sectionKeys = ['suggest', 'offer']

    sectionKeys.forEach(function(key) {
      var wrapperSelector = `.${key}-section-homepage`
      var activeSpanSelector = `.home-${key}-filter span`
      var listSelector = `.${key}-section-homepage__list`

      var wrapper = $(wrapperSelector)
      var activeSpan = $(activeSpanSelector)
      var list = wrapper.find(listSelector)

      activeSpan.html(wrapper.find('.nav-link.active').html())

      activeSpan.on('click', function() {
        list.toggleClass('open')
      })

      list.on('click', function() {
        setTimeout(function() {
          activeSpan.html(wrapper.find('.nav-link.active').html())
          list.removeClass('open')
        }, 100)
      })

    })
  },

  animate: function() {
    var $window = $(window);
    var win_height_padded = $window.height() * 1.1;

    $window.on('scroll', revealOnScroll);

    function revealOnScroll() {
      var scrolled = $window.scrollTop();

      $(".revealOnScroll:not(.animated)").each(function () {
        var $this     = $(this),
            offsetTop = $this.offset().top;

        if (scrolled + win_height_padded > (offsetTop + 200)) {
          if ($this.data('timeout')) {
            window.setTimeout(function(){
              $this.addClass('animated ' + $this.data('animation'));
              $this.css("opacity", "1")
            }, parseInt($this.data('timeout'),10));
          } else {
            $this.addClass('animated ' + $this.data('animation'));
            $this.css("opacity", "1")
          }
        }
      });
    }
  },

  mobileCatalogFilter: function() {
    let dropdowns = $('.catalog-page .dropdown')

    $("<div class='icon-close d-lg-none'></div>").prependTo('.dropdown-menu-price')

    dropdowns.find('.btn').on('click', function() {
      if (window.innerWidth <= 991) {
        $('body').addClass('hidden-body')
      }
    })

    dropdowns.on('hidden.bs.dropdown', function () {
      if (window.innerWidth <= 991) {
        $('body').removeClass('hidden-body')
      }
    })
  },

    // Show products in tablets with long height
  addClassSlideInUp: function() {
    let noAnimationContainers = document.querySelectorAll(".no-animation-in-tall-screens")

    noAnimationContainers.forEach(noAnimationContainer => {
      if (window.innerHeight > 1080) {
        noAnimationContainer.classList.add("slideInUp")
        noAnimationContainer.classList.remove("revealOnScroll")
      }
    })
  }
}

GY.init()
