import Noty from 'noty'
import Swiper from 'swiper'

export function bindGYEvents() {
  window.gyEvents = {
    /**
     * Triggered when options (color, size etc) for given product are loaded.
     * @param {event} the event that was triggered
     */
    optionsLoaded: function (event) {
    },

    optionVariantChanged: function (event) {

    },

    /**
     * Triggered when photos of product have been changed.
     * @param {event} the event that was triggered
     */
    photosChanged: function (event) {
      if($(window).width() <= 1200) {
        if (window.productSwiper) {
          productSwiper.update()
          productSwiper.slideTo(0)
        } else {
          window.productSwiper = new Swiper('.product-main-slider', {
            slidesPerView: 1,
            pagination: {
              el: '.swiper-pagination-main',
              clickable: true
            },
          })
        }
      }

      if (window.relatedProductSwiper) {
        relatedProductSwiper.update()
        relatedProductSwiper.slideTo(0)
      } else {
        window.relatedProductSwiper = new Swiper('.related-products .swiper-container', {
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 20,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          breakpoints: {
            1200: {
              slidesPerView: 3,
              slidesPerGroup: 3
            },
            992: {
              slidesPerView: 2,
              slidesPerGroup: 2
            },
            768: {
              slidesPerView: 1,
              slidesPerGroup: 1
            }
          }
        })
      }
    },

    /**
     * Triggered when something is added to cart.
     * @param {event} the event that was triggered
     */
    addedToCart: function (event) {
      new Noty({
        text: event.detail.message,
        theme: 'sunset',
        type: 'success',
        layout: 'top',
        timeout: 4000
      }).show();

      $('.quick-buy').removeClass('active');
    },

    /**
     * Triggered when a 401 is returned from specific actions. For example when user tries to adds a
     * product to wishlist and he isn't already logged in.
     * Used to display login popup form.
     * @param {event} the event that was triggered
     */
    userNeedsLogin: function (event) {
      new Noty({
        text: event.detail.message,
        theme: 'sunset',
        type: 'error',
        layout: 'top',
        timeout: 4000
      }).show();
    },

    /**
     * Triggered when wishlist is loaded.
     * @param {event} the event that was triggered
     */
    wishlistLoaded: function (event) {
      window.eqHeight();
    },

    bundlesLoaded: function (event) {
      setTimeout(function () {
        window.eqHeight();
      }, 250);
    },

    /**
     * Triggered when a product is added to wishlist. Usefull to show wishlist to user.
     * @param {event} the event that was triggered
     */
    addedToWishlist: function (event) {
      new Noty({
        text: event.detail.message,
        theme: 'sunset',
        type: 'success',
        layout: 'top',
        timeout: 3000
      }).show();

    },

    lineItemDeleted: function (event) {
      new Noty({
        text: event.detail.message,
        theme: 'sunset',
        type: 'success',
        layout: 'top',
        timeout: 3000
      }).show();
    },

    /**
     * Triggered when a product already exists in wishlist. Usefull to show wishlist to user, and message.
     * @param {event} the event that was triggered
     */
    alreadyExistsInWishlist: function (event) {
      new Noty({
        text: event.detail.message,
        theme: 'sunset',
        type: 'error',
        layout: 'top',
        timeout: 3000
      }).show();
    },

    /**
     * Triggered when a wishlist item is deleted.
     * @param {event} the event that was triggered
     */
    wishlistItemDeleted: function (event) {
      new Noty({
        text: event.detail.message,
        theme: 'sunset',
        type: 'success',
        layout: 'top',
        timeout: 3000
      }).show();
    },

    /**
     * Triggered when a wishlist item is added to cart.
     * @param {event} the event that was triggered
     */
    wishlistAddedToCart: function (event) {
      new Noty({
        text: event.detail.message,
        theme: 'sunset',
        type: 'success',
        layout: 'top',
        timeout: 3000
      }).show();
    },

    /**
     * Triggered when ALL wishlist items are added to cart.
     * @param {event} the event that was triggered
     */
    wishlistAddedAllToCart: function (event) {
      new Noty({
        text: event.detail.message,
        theme: 'sunset',
        type: 'success',
        layout: 'top',
        timeout: 3000
      }).show();
    },

    /**
     * Triggered when user successfully logs in (after completing username & password in login form).
     * @param {event} the event that was triggered
     */
    userLoggedIn: function (event) {
      window.location.reload();
    },

    /**
     * Triggered when user enters incorrect login details (wrong username and/or password).
     * @param {event} the event that was triggered
     */
    userNotAuthorized: function (event) {
      new Noty({
        text: event.detail.message,
        theme: 'sunset',
        type: 'error',
        layout: 'top',
        timeout: 4000
      }).show();
    },

    /**
     * Triggered when user successfully registers.
     * @param {event} the event that was triggered
     */
    userRegistered: function (event) {
      window.location.reload();
    },

    /**
     * Triggered when user didn't register, due to validation errors (wrong password length etc).
     * @param {event} the event that was triggered
     */
    userNotRegistered: function (event) {},

    /**
     * Triggered when app is mounted.
     * @param {event} the event that was triggered
     */
    appMounted: function (event) {},

    /**
     * Triggered when the visitor clicks on a specific filter through AJAX
     * @param {event} the event that was triggered
     */
    catalogVisitStarted: function (event) {
      $('.loading-mask').addClass('show');
    },

    /**
     * Triggered when the visitor clicks on a specific filter through AJAX
     * @param {event} the event that was triggered
     */
    catalogVisitDone: function (event) {
      $('.loading-mask').removeClass('show')
      window.eqHeight()
      window.productInit()
      window.catalogFilters()
      window.scrollableInit()
      window.lazyLoadInstance.update()
      window.unfreezeBackground()
    },

    /**
     * Triggered when next page is asked using AJAX.
     * @param {event} the event that was triggered
     */
    catalogPaginate: function (event) {},

    /**
     * Triggered when we have gotten to last page of catalog.
     * @param {event} the event that was triggered
     */
    catalogLastPage: function (event) {},

    bundleCannotAddToCart: function (event) {
      $(".promotions .promotions-item .product").addClass("open")
    },

    bundleAddedToCart: function (event) {
      new Noty({
        text: event.detail.message,
        theme: 'sunset',
        type: 'success',
        layout: 'top',
        timeout: 4000
      }).show()
    },
    
    cannotAddToCart: function () {
      if($('.product-page').length) {
        var error_element = $('.product-description .product-size')
        var error_message = error_element.data('error')
        error_element.find('.size-error').html(error_message).css('display', 'block')

        setTimeout(function() {
          error_element.find('.size-error').html('').hide()
        }, 3000)
      }
    }
  }
}
